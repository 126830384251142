import axios from 'axios';

export const requestTokensFromRefreshToken = async () => {
    const authConfig = JSON.parse(atob(sessionStorage.getItem('authConfig')));
    const refreshToken = localStorage.getItem('refreshToken');

    const payload = new URLSearchParams({
        grant_type: 'refresh_token',
        client_id: authConfig.clientId,
        refresh_token: refreshToken,
        redirect_uri: 'https://redirect.acganalytics.com/',
    });

    return axios.post(authConfig.tokenEndPoint, payload);
};
