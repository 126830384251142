import { createContext, useContext } from 'react';

import { FetchAuthProviderResponse } from '@bae/api';
import { UserData } from '@bae/data-interface';

export interface AuthConfigContextInterface {
    authConfig: FetchAuthProviderResponse | null;
    handleOAuthSignIn: (callback?: (err: string) => void) => Promise<void>;
    signOut: () => Promise<void>;
}

export const AuthConfigContext = createContext<AuthConfigContextInterface>({
    authConfig: null,
    handleOAuthSignIn: async () => {},
    signOut: async () => {},
});

export const useAuthConfig = (): AuthConfigContextInterface => {
    const context = useContext(AuthConfigContext);

    if (context === undefined) {
        throw new Error(
            'useAuthConfig must be used within a AuthConfigProvider',
        );
    }

    return context;
};

export interface TokenContextInterface {
    token: string | null;
    setToken: (newToken: string | null) => void;
    loggedIn?: () => boolean;
    isAuthorized: boolean;
    loading: boolean;
}

export const TokenContext = createContext<TokenContextInterface>({
    token: null,
    setToken: () => {},
    isAuthorized: false,
    loading: true,
    loggedIn: () => false,
});

export const useToken = (): TokenContextInterface => {
    const context = useContext(TokenContext);

    if (context === undefined) {
        throw new Error('useToken must be used within a TokenProvider');
    }

    return context;
};

export interface UserContextInterface {
    user: UserData | null;
    setUser?: (user: UserData) => void;
}

export const UserContext = createContext<UserContextInterface>({
    user: null,
});

export const useUser = (): UserContextInterface => {
    const context = useContext(UserContext);

    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }

    return context;
};
